.product-images {
  font-family: sans-serif;
  text-align: center;
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -8px;
  flex-wrap: wrap;
}

.drop-zone {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.drop-zone__container {
  position: relative;
  display: flex;
  margin-top: 8px;
  margin-left: 8px;
  font-size: 54px;
  color: grey;
  width: 120px;
  height: 120px;
  border: 1px grey dashed;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.image-preview__container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image-preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: -6px;
  right: -8px;
  padding-bottom: 1px;
  background-color: #e75b64;
  color: white;
  border-radius: 50%;
  font-size: 16px;
  width: 19px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
  cursor: pointer;
  user-select: none;
}

.top-image {
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  padding: 4px 0px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.px-4 {
  clear: both;
  margin-top:30px;
}