/* @import url('views/admins-style.css'); */
@import url("../views/Orders/Order.scss");
@import url("../views/Orders/OrderDetail/OrderHeader.scss");
@import url("../views/Users/UserDetail/UserInfo.scss");

:focus {
  outline: 0 !important;
}
/* for large */
:root{
  font-size: 16px;
  color: #ffae42
}
/* for small laptop (14in) */
@media only screen and (min-width: 600px) and (max-width: 1300px) {
  :root{
    font-size: 13px;
  }
}
/* for mobile */
@media only screen and (max-width: 600px) {
  :root {
    font-size: 16px;
  }
}

/* scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0b0b0; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #969595;
}
/* ::-webkit-scrollbar-button:single-button {
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} */

/* Up */
/* ::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
} */

/* ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} */
/* Down */
/* ::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
} */

/* ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
} */
/*.app-header {
  background-color: #FE5522
}*/

/* BEGIN Admin Button Drawer */
/* .app-header .navbar-brand img {
  cursor: pointer;
} */
.app-header .navbar-toggler {
  min-width: 0;
}
.app-header .navbar-toggler-icon {
  background: url("../assets/img/icon/menu.png");
  background-size: cover;
}
.app-header .navbar-toggler-icon:hover,
.app-header .navbar-toggler:hover .navbar-toggler-icon {
  background: url("../assets/img/icon/menu_hover.png");
  background-size: cover;
}
/* END Admin Button Drawer */

/* BEGIN Breadcrumb */
.breadcrumb {
  background: transparent;
  border-color: transparent;
}
.breadcrumb-item a {
  color: #42b549;
}
/* END Breadcrumb */

/* button */
.btn-icon-user {
  color: #ffffff;
  font-size: 27px;
  padding-bottom: 3px;
  padding-top: 3px;
}

.btn-delete {
  background: transparent;
  border: unset;
  color: #f86c6b;
  font-size: 18px;
  padding: unset;
  height: 40px !important;
}

.btn-delete:hover {
  background: transparent;
  border: unset;
  padding: unset;
}

.btn-delete.btn-secondary:not(:disabled):not(.disabled):active {
  background: transparent;
  border: unset;
  box-shadow: unset;
  color: #000000;
}

.btn-green {
  color: #ffffff;
  background-color: #42b549;
  border-color: #42b549;
  border-radius: 50em;
  font-size: 1rem;
  min-width: 120px;
  height: 40px !important;
}
.btn-green:hover,
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: #ffffff;
  background-color: #3ea662;
  border-color: #3a9d5d;
}

.btn-red {
  color: #ffffff;
  background-color: #f86c6b;
  border-color: #f86c6b;
  border-radius: 50em;
  font-size: 1rem;
}
.btn-red:hover{
  color: #fff;
  background: #ac4342;
}

.btn-red:disabled{
  background: #ffd4d4;
  border-color: #ffd4d4;
}

.btn-white, .btn-white:disabled {
  color: #42B549;
  background-color: #ffffff;
  border-color: #42B549;
  border-radius: 50em;
  font-size: 1rem;
  height: 40px !important;
  min-width: 120px;
}
.btn-white:hover {
  color: #42b549;
  background-color: #f7f4f4;
  border-color: #42b549;
}

.small-btn-white, .btn-white:disabled {
  color: #42B549;
  background-color: #ffffff;
  border-color: #42B549;
  border-radius: 50em;
  font-size: 1rem;
  height: 40px !important;
}
.small-btn-white:hover {
  color: #42b549;
  background-color: #f7f4f4;
  border-color: #42b549;
}

.Toastify__toast-container{
  width: 100% !important;
}

.btn-export {
  background: #42B549;
  display: block;
  height: 40px !important;
}
.btn-export:hover {
  color: #FFFFFF;
  background-color: #3EA662;
  border-color: #3A9D5D;
}

.btn-upload-approve {
  background: unset;
  border: unset;
  cursor: pointer;
  color: #42B549;
  font-size: 16px;
  padding: unset;
  text-decoration: underline;
  padding-bottom: 20px;
  /* height: 40px !important; */
}
.btn-upload-reject {
  background: unset;
  border: unset;
  cursor: pointer;
  color: #e01414;
  font-size: 16px;
  padding: unset;
  text-decoration: underline;
  padding-bottom: 20px;
  /* height: 40px !important; */
}

button.disabled:hover,
input.form-control:disabled {
  cursor: not-allowed;
}
/* end button */

.custom-input .form-check-input {
  margin-left: 0;
}

.custom-tab-style a.active.nav-link {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-tab-style a.nav-link {
  white-space: nowrap;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-tab-style .custom-tab-content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: white;
}

.custom-tab-style-10 a.active.nav-link {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: none;
  border-left: none;
  border-right: none;
  min-width: 40px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-tab-style-10 a.nav-link {
  white-space: nowrap;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.custom-tab-style-10 .custom-tab-content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: white;
}

.custom-tab-nav.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}
.nav-tabs .nav-item {
  margin-bottom: 0;
}

.custom-card-style {
  border-radius: 8px;
  border: none;
  overflow: visible !important;
}

.custom-card-style label {
  font-size: 10px;
  color: #858585;
}

.custom-card-style select {
  height: 40px;
}

.form-control {
  height: 40px;
}

.form-control-dashboard {
  height: 30px !important;
  font-size: 12px !important;
}


.ds-none {
  display: none;
}
.ds-block {
  display: block;
}

.fc-aqua {
  color: #63C2DE;
}
.fc-green {
  color: #008000;
}
.fc-grey {
  color: #4A4A4A;
}
.fc-red {
  color: #E01414;
}
.fc-white {
  color: #FFFFFF;
}

.form-control:focus {
  border-color: #8ad4ee;
  box-shadow: 0 0 0 1px rgba(32, 168, 216, 0.25);
}

.form-control:disabled {
  background: hsl(0,0%,95%);
}

.fw-bold {
  font-weight: bold;
}

.header-fixed .app-header {
  background-color: #fe5522;
  border-color: transparent;
  padding-left: 15px;
  padding-right: 15px;
}

input[type="file"].input-file-image {
  border: 1px solid #e4e7ea;
  line-height: 22px;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.input-error {
  background: #FFF8F8;
  border-color: #E01414;
}

.input-success {
  background: #FFFFFF;
  border-color: #E4E7EA;
}

.logo-login {
  background: url("../assets/img/brand/logo-ideal-horizontal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 120px;
  margin-bottom: 30px;
}

.max-wid-150 {
  max-width: 150px;
}

.m-b-0 {
  margin-bottom: 0px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-40 {
  margin-bottom: 40px;
}

.m-l-5 {
  margin-left: 5px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-min-15 {
  margin-left: -15px;
}
.m-r-min-15 {
  margin-right: -15px;
}

.m-l-min-20 {
  margin-left: -20px;
}
.m-r-min-20 {
  margin-right: -20px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-5 {
  margin-top: 5px;
}

/* .m-0 {
  margin: 0;
} */

.nav-tabs .nav-link.active,
.custom-tab-nav.nav-tabs .nav-link:hover {
  color: #42b549;
}

.pd-20 {
  padding: 20px;
}

.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-16 {
  padding-bottom: 16px;
}

.p-l-8 {
  padding-left: 8px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-r-15 {
  padding-right: 15px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-r-30 {
  padding-right: 30px;
}

.p-lr-15 {
  padding: 0 15px !important;
}

.p-lr-0 {
  padding: 0px 0px 0px 1px !important;
}

/* BEGIN Admin Sidebar Navigation */
.sidebar,
.sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
  background: #ffffff;
}
.sidebar .nav-dropdown-toggle::before {
  transform: rotate(-90deg);
}
.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  transform: rotate(90deg);
}
.sidebar .nav-dropdown.open {
  /* background: rgba(0, 0, 0, 0.08); */
  background: #ffffff;
}
.sidebar .nav-dropdown.open .nav-link:hover {
  color: #ffffff;
}
.sidebar .nav-dropdown.open .nav-link,
.sidebar .nav-link,
.sidebar .nav-link .nav-icon {
  color: #4a4a4a;
}
.sidebar .nav-link.active,
.sidebar-minimized .sidebar .nav-item:hover > .nav-link.active {
  background: #fff4f1;
  color: #42b549;
}
.sidebar .nav-link.active .nav-icon,
.sidebar .nav-link.active:hover .nav-icon,
.sidebar .nav-dropdown.open .nav-dropdown-items .nav-item .nav-link.active {
  color: #42b549;
}
/* END Admin Sidebar Navigation */

.style-label label {
  color: #858585;
  font-size: 10px;
  text-transform: uppercase;
}

.table-body-user-document tr td {
  border-top: 1px solid #e8e8e8;
  padding: 10px 20px;
  vertical-align: middle !important;
}

.table-head-user-document tr th {
  border-bottom: 2px solid #e8e8e8;
  padding: 10px 20px;
}

.table-hover .table-body-user-document tr:hover,
.table-striped .table-body-user-document tr:nth-of-type(odd):hover {
  background-color: #fff4f1;
  transition: ease 0.2s;
}

.table-row,
.table-hover .table-body-user-document tr,
.table-striped tbody tr:nth-of-type(odd) {
  /* cursor: pointer; */
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
}
.table-row:hover {
  transition: ease 0.2s;
  background-color: #fff4f1;
}

.table-link-view span.btn-approve {
  cursor: pointer;
  color: #42b549;
  font-size: 16px;
  text-decoration: underline;
}
.table-link-view span.btn-reject {
  cursor: pointer;
  color: #e01414;
  font-size: 16px;
  margin-left: 30px;
  text-decoration: underline;
}

.text-intial {
  text-transform: initial;
}

.text-spacing-5 {
  letter-spacing: 5px;
}

/* BEGIN Toggle Hide Password */
.toggle-change-input {
  cursor: pointer;
  margin: 7px 10px;
  position: absolute;
  right: 15px;
}
/* END Toggle Hide Password */

.wrap-usersaldo-filter {
  margin: 10px;
  width: 70%;
  z-index: 1;
}

.wrap-userorder-filter {
  margin: 10px;
  width: 50%;
  z-index: 1;
}

.wrap-modal-btn-change {
  padding: 3rem 1.5rem;
}

.wrap-ds-flex {
  display: flex;
  flex-wrap: wrap;
}

.wrap-collection-add-product {
  margin-left: unset;
  margin-right: unset;
  margin-bottom: 1.5rem;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 575.98px) {
  .sm-mb-4 {
    margin-bottom: 1.5rem;
  }
  .sidebar-show {
    position: fixed;
  }
    .sidebar .main {
      position: fixed;
      overflow: hidden;
      height: 100vh;}
    .sidebar .app-footer {
      position: fixed;
      overflow: hidden;}
    .sidebar .nav {
      padding-bottom: 20vh;
    }
}

@media only screen and (min-width: 576px) {
  .wrap-image-modal {
    /* background: rgba(0, 0, 0, 0.8); */
    height: 100%;
    margin: unset;
    max-width: 100%;
  }

  .wrap-image-modal .modal-content {
    /* height: 85%; */
    height: 65%;
    margin: auto;
    /* max-width: 85%; */
    max-width: 550px;
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 1s;
    animation-name: zoom;
    animation-duration: 1s;
  }

  .transparent .modal-content {
    height: 100%;
    width: 100%;
    /* height: 80%; */
    margin: auto;
    /* max-width: 100%; */
    /* max-width: 550px; */
    background: rgba(255, 255, 255, 0.0);
    border: 1px solid rgba(0, 0, 0, 0.0);
    border-radius: 0.3rem;
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 1s;
    animation-name: zoom;
    animation-duration: 1s;
  }

}

@media only screen and (max-width: 767px) {
  .custom-tab-nav {
    flex-wrap: nowrap;
    /* overflow-x: scroll; */
    overflow-y: hidden;
  }

  .custom-tab-nav.nav-tabs .nav-link {
    height: 100%;
    text-align: center;
  }

  .sm-d-flex {
    display: flex;
  }

  .sm-overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm-m-b-16 {
    margin-bottom: 1rem;
  }

  .sm-m-b-24 {
    margin-bottom: 1.5rem;
  }

  .sm-min-width-0 {
    min-width: 0 !important;
  }

  .sidebar-show {
    position: fixed;
  }
    .sidebar .main {
      position: fixed;
      overflow: hidden;
      height: 100vh;}
    .sidebar .app-footer {
      position: fixed;
      overflow: hidden;}
    .sidebar .nav {
      padding-bottom: 20vh;
    }
  
    
}

@media only screen and (min-width: 991px) {
  .brand-minimized .app-header .navbar-brand {
    width: 100px;
  }
}

[class^="MuiPaper-elevation2-"],
[class*=" MuiPaper-elevation2-"] {
  box-shadow: none !important;
}

.modal-button {
  flex: 1;
  margin: 5px;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 400px) {
  .modal-button {
    width: 50%;
    margin: 0px;
    padding: 10px;
  }

  .modal-button .modal-cancel-button {
    float: right;
  }

  .modal-button .modal-confirm-button {
    float: left;
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 15px;
}

.t-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-wait {
  cursor: wait !important;
}
.custom-thumb-image {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border: solid #979797 1px;
}
.custom-thumb-image img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

.balance-history-detail__container {
  padding: 4px 32px;
}

.balance-history-detail__table>tbody>tr>td {
  color: rgb(66, 97, 117);
  font-weight: 500;
  font-size: 0.82rem;
}

.balance-history-detail__table>tbody>tr>td:nth-child(2) {
  padding: 0px 24px 0px 2px;
}

.collapsible {
  background-color: rgb(53, 37, 37);
  padding-left: 10px;
  color: white;
  width: 10 0%;
}

.pr-150 {
  padding-right: 150px;
}

.withdraw-container {
  background-color: red;
}

.withdraw-table {
  border: 1px solid black;
}

.withdraw-table>tr>th {
  padding-left: 50px;
  padding-right: 50px;
}

.withdraw-table>tr>th, .withdraw-table>tr>td {
  border: 1px solid black;
}

.withdraw-table>tr>td {
  text-align: center;
}

.payment-proof-image {
  width: 100%;
  height: auto;
}

.MuiTab-textColorPrimary-178.MuiTab-selected-180 {
  color: #42b549 !important;
}

.MuiPrivateTabIndicator-colorPrimary-188 {
  background-color: #42b549 !important;
}
/* fix arrow popper */
.react-datepicker-popper[data-placement$="end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-calendar {
  border: 0 !important;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  width: 100% !important;
}

/* mainlist */

/* to indicate active button */
.custom-paging button:not([disabled])[tabindex='-1']{
  /* color: #fe5522; */
  font-weight: bolder; 
  color: black;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.08);
}

.flex-start-paging-panel {
  /* color: #fe5522; */
  justify-content: flex-start !important;
}

.w-100p{
  width: 100% !important;
}
.h-100p{
  height: 100% !important;
}

/* background */
.bg-white {
  background: white;
}
.bg {
  background-color: #f4f4f4 !important;
}
.bg-primary{
  background-color: #fe5522 !important;
}
.bg-secondary{
  background-color: #42b549 !important;
}
.bg-info{
  background-color: #20a8d8 !important;
}
.bg-danger{
  background-color: #ff0000 !important;
}
.bg-warning{
  background-color: #ffae42 !important;
}
/* text */
.text-label {
  color: #858585 !important;
  font-size: 10px;
}
.text-muted{
  color: #858585 !important;
}
.text-info{
  color:  #20a8d8 !important;
}
.text-success{
  color: #008000 !important;
  /* color: #42b549 !important; */
}
.text-danger, .hover-text-danger:hover{
  color: #ff0000 !important;
  /* color: #f86c6b !important; */
}
.text-warning{
  color: #ffae42 !important;
}
.text-primary{
  color: #fe5522 !important;
}
.text-secondary{
  color: #42b549 !important;
}

.break-word{
  word-break: break-all;
  white-space: normal;
}
/* card image */
.card-image:hover{
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

/* error form */
.form-error {
  border-color: #e4e7ea !important;
}
.form-error.invalid{
  border-color: #f86c6b !important;
}
.form-error.invalid:focus{
  box-shadow: 0 0 0 0.2rem rgb(248 108 107 / 25%) !important;
}

/* slider  */
.custom-arrow::before{
  color: #3a9d5d !important;
}
.slick-dots li.slick-active button::before{
  color: #fe5522 !important;
}
/* for merchant */
.mini-slider .custom-arrow::before{
  font-size: 15px;
}
.mini-slider .slick-prev.custom-arrow{
  left: -15px;
}
.mini-slider .slick-next.custom-arrow{
  right: -20px;
}
.mini-slider .slick-dots li button::before{
  line-height: 0;
}
.mini-slider .slick-dots li {
  width: 10px;
  margin: 0;
}
.mini-slider .slick-slide > div {
  display: grid;
  place-items: center;
  margin: auto;
  height: 30px;
  padding: 0px;
}
.slick-slide > div {
  display: grid;
  place-items: center;
  margin-top: 50px;
  margin: auto;
  height: 500px;
  padding: 0px;
}
/* layout */
.d-flex{
  display: 'flex';
}
.d-flex.wrap{
  flex-wrap: wrap;
}
.d-flex.column{
  flex-direction: column;
}
.a-i-center{
  align-items: center !important;
}
.a-i-start{
  align-items: flex-start !important;
}
.j-c-center{
  justify-content: center !important;
}
.j-c-between{
  justify-content: space-between !important;
}
.d-flex.flex-container {
  margin: 0 -4px;
}
.d-flex.flex-container > * {
  padding: 0 4px;
}
* > .d-hover{
  display: none !important;
}
*:hover > .d-hover{
  display: block !important;
}

.hover:hover{
  background-color: #e9e9e9;
}
.selected{
  background-color: #f4f4f4;
}

@media only screen and (max-width: 600px) {
  .d-flex.flex-column-xs {
    flex-direction: column;
  }
}
.progress-bar{
  min-height: 4px;
  border-radius: 10px;
}

/* text */
.ellipsis{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}

/* dialog */
.custom-dialog-paper > div > div{
  margin: 48px auto !important;
}

/* quill */
.quill {
  position: relative;
}
.quill .ql-toolbar {
  position: sticky !important;
  top: 0 !important;
  z-index: 200 !important;
  background: white;
}
.ql-snow .ql-editor img{
  max-width: 300px !important;
  display: block !important;
  height: auto !important;
  object-fit: contain !important;
}

/* for setting  */
.setting-version .ql-editor{
  min-height: 200px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.sidebar .nav-link:hover {
  /* font-size: 12px; */
  background-color: #fff;
  color: #23282c !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.sidebar .nav-dropdown.open .nav-dropdown-items {
  margin-left: 10px;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.sidebar .nav-dropdown.open .nav-link:hover {
  background-color: rgba(255,255,255,0.5);
  color: #23282c !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.sidebar .nav-link:hover.nav-dropdown-toggle::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2323282c' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !important;
}

.sidebar .nav-link:hover .nav-icon {
  color: #23282c !important;
}

.sidebar .nav-link:hover .multi-icon {
  color: #42b549 !important;
}

.sidebar .nav-link.active:hover,
.sidebar .nav-dropdown.open .nav-link.active:hover {
  background-color: #fff4f1;
  color: #42b549 !important;
}

.sidebar .nav-link.active:hover .nav-icon,
.sidebar .nav-link.active:hover .nav-icon .multi-icon {
  color: #42b549 !important;
}

.sidebar-minimized .sidebar .nav-item:hover {
  width: 250px;
  overflow: visible; }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #fff; 
    color: #23282c !important;
  }

.sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
  background: #fff !important;
  color: #23282c !important;
}

.multi-icon {
  color: #42b549 !important;
}

/* color: #20a8d8; */
/* color: #fe5522; */
/* .sidebar .nav-dropdown .nav-link .multi-region, .sidebar .nav-link .multi-region, .sidebar .nav-link .nav-icon .multi-icon {
  color: #42b549;
} */

/* color: #20a8d8; */
/* background: #fe5522; */
/* color: #fff; */
/* .sidebar .nav-dropdown .nav-link:hover .multi-region, .sidebar .nav-link:hover .multi-icon {
  color: #42b549;
} */

/* color: #20a8d8 !important; */
/* color: #fe5522 !important; */
/* .sidebar .nav-link.active:hover .multi-icon {
  color: #42b549 !important;
} */

.parent-multi, .child-multi {
  display: none;
}

/* .sidebar .nav-dropdown .nav-link:hover .multi-region .parent-multi, 
.sidebar .nav-link:hover .multi-region .parent-multi,
.sidebar .nav-dropdown .nav-link:hover .multi-region .child-multi, */
.sidebar .nav-dropdown.open .multi-region .parent-multi,
.sidebar .nav-link.active .multi-region .parent-multi,
.sidebar .nav-dropdown.open .nav-link.active .multi-region .child-multi {
  display: inline;
}

details {
  border: 1px solid;
  border-radius: 3px;
  padding: .5em;
  border-color: #e4e7ea;
}

summary {
  font-weight: bold;
  margin: -.5em -.5em -.5em;
  padding: .5em;
  border-radius: 3px;
}

details[open] {
  padding: .5em;
  border-radius: 3px;
  border-color: #e2e2e2;
}

details[open] summary {
  /* border-bottom: 1px solid #aaa; */
  margin-bottom: .5em;
}

.dropzone {
  border: 1.5px dashed #e4e7ea;
  border-radius: 0.25rem;
  font-size: x-small;
  color: #73818f;
}

.dropzone .dropzone-child {
  border-radius: 0.25rem;
  font-size: x-small;
  padding: 5.5px;
  border: 1px solid #e4e7ea;
  color: #73818f;
}

.videocall {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.sidebarmodule::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

@media (max-width: 800px) {
  .d-flex .responsive-container {
    flex-direction: column;
  }
  .flex-responsive-item {
    width: '100%'
  }
}

mark {
  background: yellow;
}

mark.current {
  background: orange;
}

/* Categories Container */
.CategoryContainer {
  box-sizing: border-box;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
}
.CategoryContainerTitle {
  box-sizing: border-box;
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}
.CategoryHeader  {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px;
  background-color: rgb(246, 246, 246, 0.6);
  border-bottom: solid 1px rgb(141, 139, 139, 0.3);
}
.CategoryContent {
  box-sizing: border-box;
  width: 100%;
  min-height: 200px;
  /* border: solid 2px rgb(66, 181, 73); */
}
.Content {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
}
.contentBox {
  box-sizing: border-box;
  width: 32%;
  min-height: 200px;
  box-shadow: 0 0.4px 4px 0.1px rgb(125, 125, 125, 0.6);
  padding: 5px;
  max-height: 85vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.parentBoxContainer{
  box-sizing: border-box;
  background-color: bisque;
  padding: 10px;
  margin-bottom: 10px;
}
.parentNameBox {
  box-sizing: border-box;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 5px 0 ;
  border-bottom: solid 2px rgb(162, 162, 162);
  margin-bottom: 10px;
}
/* CODE CSS FOR SEARCH FROM */
.CategoryFormSearch {
  box-sizing: border-box;
  /* width: 40%; */
  /* min-height: 45px; */
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* box-shadow: 0 0.3px 2px 0.6px rgba(167, 167, 167, 0.5); */
  border-radius: 4px;
}
.CategoryFormSearchSelect {
  box-sizing: border-box;
  height: 30px;
  width: 150px;
  outline-style: none;
  background-color: rgb(255, 255, 255);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 0 2px 0 rgb(62, 62, 62);
  border-radius: 3px;
  cursor: pointer;
}
.CategoryFormSearchSelectCon {
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}
.CategoryFormSearchContent {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
  padding-top: 4px;
  border: solid 1px rgb(211, 211, 211);
  display: none;
  z-index: 999;
  background-color: inherit;
}
.CategoryFormSearchOpt {
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  background-color: whitesmoke;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.CategoryFormSearchOpt:hover {
  background-color: rgb(233, 233, 233);
}
.CategoryFormSearchSelectIkon {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 20px;
  height: 20px;
  background-color: white;
}
.CategoryFormSearchInput {
  box-sizing: border-box;
  padding: 5px 10px;
  width: 100%;
  height: 30px;
  background-color: inherit;
  position: relative;
}
.CategoryFormSearchSugestion {
  box-sizing: border-box;
  position: absolute;
  box-shadow: 0 0 4px 0 rgb(147, 147, 147);
  top: 40px;
  z-index: 999;
  background-color: rgb(242, 242, 242);
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  display: flex;
  padding: 5px;
  overflow: hidden;
}
.CategoryFormSearchSugestionContent {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
}
.CategoryFormSearchSugestionLink {
  box-sizing: border-box;
  width: 100%;
  min-height: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 4px;
}
.CategoryFormSearchInput input {
  box-sizing: border-box;
  border-style: none;
  outline-style: none;
  width: 100%;
  font-size: 16px;
  height: 100%;
  background-color: inherit;
  border-left: solid 2px black;
  padding-left: 10px;
}
.CategoryFormSearchBtn {
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 1px 4px 0 rgb(161, 161, 161);
}

/* CODE CSS DI BAWAH INI UNTUK BOX LEVEL 1 */
.boxLevel1 {
  box-sizing: border-box;
  width: 100%;
  min-height: 80px;
  /* background-color: rgb(254, 85, 34); */
  background-color: white;
  padding: 7px 7px;
  display: flex;
  margin-bottom: 8px;
  position: relative;
  box-shadow: 0 1px 4px 1px rgb(160, 160, 160);
}
.boxLevel1Left {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  background-color: rgb(255, 255, 255);
}
.boxLevel1Right {
  width: 65%;
  padding: 0 6px;
}
.boxLevel1Img {
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.boxLevel1Img img {
  width: 100%;
}
.boxLevel1RightTitle {
  display: flex;
  width: 100%;
  min-height: 20px;
  font-size: 16px;
  text-align: start;
  font-weight: bold;
}
.boxLevel1RightStatus {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: azure;
  display: flex;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
}
.boxLevel1RightStatusIkon {
  width: 10px;
  height: 10px;
  background-color: green;
  margin-left: 4px;
  border-radius: 50%;
}
.boxLevel2Right {
  box-sizing: border-box;
  width: 100%;
  /* background-color: gold; */
}
.boxLevel2Content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(255, 255, 255);
  /* padding: 10px 0; */
}
.boxLevel2RightContent {
  box-sizing: border-box;
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-radius: 2px; */
}
.boxLevel2RightContent:hover {
  border-color: rgb(157, 157, 157);
}
.boxLevel2RightTitle {
  display: flex;
  width: 100%;
  min-height: 14px;
  /* font-size: 12px; */
  text-align: start;
  font-weight: bold;
  /* margin-bottom: 10px; */
}
.boxLevel2RightContent input {
  box-sizing: border-box;
  width: 100%;
  background-color: inherit;
  /* border-style: none;
  outline-style: none; */
  height: 30px;
}
.boxLevel2RightContent button {
  box-sizing: border-box;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
  outline-style: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 0 6px;
}
.boxLevel2RightContent button:hover {
  background-color:rgb(66, 181, 73);
}
.boxLevel2RightContent {
  box-sizing: border-box;
}
.boxLevel2ContentBtn {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.boxLevel2ContentBtn button {
  box-sizing: border-box;
  width: 50%;
  height: 30px;
  cursor: pointer;
  border-radius: 4.5px;
  box-shadow: 0 1px 4px 0 rgb(188, 187, 187, 0.5);
  border-style: none;
  font-weight: bold;
}
.boxLevel2ContentBtn button:hover {
  background-color: white;
}

/* Context Menu */

.contextMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: auto;
  width: fit-content;
}

.optionsList {
  padding: 12px;
  min-width: 107px;
  border-radius: 4px;
}

.optionListItem {
  padding: 4px;
  border-radius: 3px;
  list-style-type: none;
}

.optionListItem:hover {
  cursor: pointer;
}

.customContextmenuAreaListWrapper {
  background-color: #5e35b1;
}

.customContextmenuAreaListItem {
  /* color: white; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.customContextmenuAreaListItem:hover {
  background-color: tomato;
  color: white;
}
.pinch-pan-pointer {
  pointer-events: all !important;
}
/* Context Menu */

.disabled-select-text {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.magnifier-image > div > div > img {
  max-height: 75vh;
}

.kpi-crumb > nav > ol {
  padding: 0;
  padding-inline-start: 0;
  margin-bottom: 1rem;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e4e7ea;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.kr-task-card {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

/* Card */
/* .card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 40%;
  border-radius: 5px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

img {
  border-radius: 5px 5px 0 0;
}

.container {
  padding: 2px 16px;
} */

[sm-notification-after-text],
[sm-notification-before-text] {
	--badge-offset-x: calc(0px - var(--badge-size) / 3);
	--badge-offset-y: calc(0px - var(--badge-size) / 3);
	--badge-size: 0.8rem;
	--circle-size: 0.8rem;
	--dot-offset: 0.5rem;
	--dot-size: 0.5rem;
		
	--b: initial;
	--bgc: hsl(195, 100%, 30%);
	--bdrs: 0;
	--c: hsl(195, 100%, 99%);
	--d: inline-flex;
	--fz: 0.425rem;
	--fw: 700;
	--h: auto;
	--l: initial;
	--m: 0.4rem;
	--p: 0;
	--pos: static;
	--r: initial;
	--t: initial;
	--tt: uppercase;
	--w: initial;
	
	position: relative;
}

[sm-notification-after-text]:not([sm-notification-after-text=""])::after {
	content: attr(sm-notification-after-text);
}
[sm-notification-before-text]:not([sm-notification-before-text=""])::before {
	content: attr(sm-notification-before-text);
}

[sm-notification-after-text]:not([sm-notification-after-text=""])::after,
[sm-notification-before-text]:not([sm-notification-before-text=""])::before {
	align-items: center;
	background: var(--bgc);
	border-radius: var(--bdrs);
	bottom: var(--b);
	box-shadow: var(--bxsh);
	box-sizing: border-box;
	color: var(--c);
	display: var(--d);
	font-size: var(--fz);
	font-weight: var(--fw);
	height: var(--h);
	justify-content: center;
	left: var(--l);
	padding: var(--p);
	position: var(--pos);
	right: var(--r);
	text-decoration: none;
	text-transform: var(--tt);
	top: var(--t);
	width: var(--w);
}

/* MODIFIERS */
[sm-notification-after-type*="badge"]::after,
[sm-notification-before-type*="badge"]::before {
	--bdrs: var(--badge-size);
	--bxsh: 0 0 0 2px rgba(255, 255, 255, 0.5);
	--h: var(--badge-size);
	--p: 0;
	--pos: absolute;
	--w: var(--badge-size);
}
[sm-notification-after-type*="circle"],
[sm-notification-before-type*="circle"]{
	align-items: center;
	display: flex;
}
[sm-notification-after-type*="circle"]::after,
[sm-notification-before-type*="circle"]::before {
	--bdrs: 50%;
	--fw: 400;
	--h: var(--circle-size);
	/* --pos: relative;
	--t: -0.75em; */
	--tt: initial;
	--w: var(--circle-size);
}
[sm-notification-after-type*="circle"]::after,
[sm-notification-after-type*="pill"]::after {
	margin-inline-start: 1ch;
}
[sm-notification-before-type*="circle"]::before,
[sm-notification-before-type*="dot"]::before,
[sm-notification-before-type*="pill"]::before {
	margin-inline-end: 1ch;
}
[sm-notification-after-type*="dot"]::after,
[sm-notification-before-type*="dot"]::before {
	--bdrs: 50%;
	--d: inline-block;
	--fz: 50%;
	--h: var(--dot-size);
	--p: 0;
	--pos: relative;
	--t: -1px;
	--w: var(--dot-size);
}
[sm-notification-after-type*="dot"]::after,
[sm-notification-before-type*="dot"]::before {
	content: "" !important;
}
[sm-notification-after-type*="pill"]::after,
[sm-notification-before-type*="pil"]::before {
	--bdrs: 1rem;
	--p: 0.25rem 0.75rem;
	--pos: relative;
	--t: -1px;
}

/* COLORS */
[sm-notification-after-type*="blue"]::after,
[sm-notification-before-type*="blue"]::before {
	--bgc: #007acc;
}
[sm-notification-after-type*="darkgray"]::after,
[sm-notification-before-type*="darkgray"]::before {
	--bgc: #706e6b;
	--c: #fff;
}
[sm-notification-after-type*="green"]::after,
[sm-notification-before-type*="green"]::before {
	--bgc: #42b549;
}
[sm-notification-after-type*="lightgray"]::after,
[sm-notification-before-type*="lightgray"]::before {
	--bgc: #ecebea;
	--c: #080707;
}
[sm-notification-after-type*="orange"]::after,
[sm-notification-before-type*="orange"]::before {
	--bgc: #ffb75d;
	--c: #080707;
}

[sm-notification-after-type*="red"]::after,
[sm-notification-before-type*="red"]::before {
	--bgc: #c23934;
}

/* POSITION */
[sm-notification-after-type*="top"]::after,
[sm-notification-before-type*="top"]::before {
	--b: auto;
	--pos: absolute;
	--t: var(--dot-offset);
}
[sm-notification-after-type*="right"]::after,
[sm-notification-before-type*="right"]::before {
	--l: auto;
	--pos: absolute;
	--r: var(--dot-offset);
}
[sm-notification-after-type*="bottom"]::after,
[sm-notification-before-type*="bottom"]::before {
	--b: var(--dot-offset);
	--pos: absolute;
	--t: auto;
}
[sm-notification-after-type*="left"]::after,
[sm-notification-before-type*="left"]::before {
	--pos: absolute;
	--r: auto;
	--l: var(--dot-offset);
}
[sm-notification-after-type*="badge"][sm-notification-after-type*="top"]::after,
[sm-notification-before-type*="badge"][sm-notification-before-type*="top"]::before {
	--m: 0;
	--t: var(--badge-offset-y);
}
[sm-notification-after-type*="badge"][sm-notification-after-type*="right"]::after,
[sm-notification-before-type*="badge"][sm-notification-before-type*="right"]::before {
	--m: 0;
	--r: var(--badge-offset-x);
}
[sm-notification-after-type*="badge"][sm-notification-after-type*="bottom"]::after,
[sm-notification-before-type*="badge"][sm-notification-before-type*="bottom"]::before {
	--b: var(--badge-offset-y);
	--m: 0;
}
[sm-notification-after-type*="badge"][sm-notification-after-type*="left"]::after,
[sm-notification-before-type*="badge"][sm-notification-before-type*="left"]::before {
	--l: var(--badge-offset-x);
	--m: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.sortable-index {
  z-index: 9999;
}