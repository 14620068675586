.order-header-container {
    background-color: #FFFFFF;
    padding: 32px;
    border-radius: 6px;
}

.order-header {
    margin-bottom: 25px;
}
.order-header-label {
    color: #000000;
    font-size: 21px;
    line-height: 28px;
}

.order-detail-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.order-detail {
    display: flex;
    flex-direction: column;
}

.order-detail-hint {
    color: #858585;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: unset;
}

.order-detail-label {
    color: #000000;
    font-size: 14px;
    line-height: 19px;
}